import axios from 'axios'
import dayjs from 'dayjs'
import normalize from 'json-api-normalizer'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import { dissocPath, mergeDeepRight } from 'ramda'

import { CURRENCY_CODE } from '../../config/financial'
import accounts from '../../state/accounts'
import accountUsersEndpointConverter from './converters/accountUsersEndpoint'
import loggedInUserEndpointConverter from './converters/loggedInUserEndpoint'
import singleAccountEndpointConverter from './converters/singleAccountEndpoint'
import usageReportEndpointConverter from './converters/usageReportEndpoint'
import usersEndpointConverter from './converters/usersEndpoint'
import ventilationReportEndpointConverter from './converters/ventilationReportEndpoint'
import co2ReportEndpointConverter from './converters/co2ReportEndpoint'
import { apiProxy, realApi, realApiAsAngel } from './httpClient'

const handleError = (error) => {
  if (axios.isCancel(error)) {
    // Request cancelled, ignore
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    throw new Error(
      `${error.response.status} – ${error.response.data.errors
        .map((e) =>
          [
            e.title,
            get(e, 'source.parameter'),
            get(e, 'source.pointer'),
            e.detail,
          ]
            .filter(Boolean)
            .join(' – ')
        )
        .filter(Boolean)
        .join(', ')}`
    )
  } else {
    // The request was made but no response was received or
    // something happened in setting up the request that triggered an Error
    throw new Error(error.message)
  }
}

export async function syncProperty({ propertyId }) {
  try {
    await apiProxy().post('/v6/property-syncs', {
      data: {
        type: 'property-syncs',
        relationships: {
          property: {
            data: {
              type: 'properties',
              id: propertyId,
            },
          },
        },
      },
    })
  } catch (error) {
    handleError(error)
  }
}

export async function fetchAccountUsers({ email, password }) {
  try {
    // The login request should always go through the real API
    const { data } = await realApi.get(
      '/v6/account-users?include=account,user',
      {
        auth: {
          username: email,
          password: password,
        },
      }
    )
    return accountUsersEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchAdminAccount({ id }) {
  try {
    // This request will only be made by a logged-in angel, thus the real API
    const { data } = await realApiAsAngel.get(`/v6/accounts/${id}`)
    if (get(data, ['data', 'attributes', 'role']) !== 'admin') {
      throw new Error(`ID ${id} er ikke en admin konto`)
    }
    return singleAccountEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchUsers(accountId) {
  try {
    const { data } = await apiProxy().get(
      `/v6/users?filter[account-id]=${accountId}`
    )
    return usersEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchLoggedInUser() {
  try {
    const { data } = await apiProxy().get('/v6/ping?include=user')
    return loggedInUserEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function inviteUser({ accountId, userName, userEmail }) {
  try {
    if (!accountId) {
      throw new Error('Mangler id på konto for at kunne invitere bruger')
    }
    if (!userEmail) {
      throw new Error('Mangler email adresse for at kunne invitere bruger')
    }
    await apiProxy().post(`/v6/accounts/${accountId}/invitations`, {
      data: {
        type: 'account-invitations',
        attributes: {
          'user-name': userName,
          'user-email': userEmail,
        },
      },
    })
  } catch (error) {
    handleError(error)
  }
}

export async function fetchVentilationReport({ roomId, fromDate, toDate }) {
  try {
    const { data } = await apiProxy().post('/v6/reports/ventilation', {
      data: {
        type: 'ventilation-reports',
        relationships: {
          room: {
            data: {
              type: 'rooms',
              id: roomId,
            },
          },
        },
        attributes: {
          'to-date': toDate,
          'from-date': fromDate,
        },
      },
    })
    return ventilationReportEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchCo2Report({ roomId, fromDate, toDate }) {
  try {
    const { data } = await apiProxy().post('/v6/reports/co2', {
      data: {
        type: 'co2-reports',
        relationships: {
          room: {
            data: {
              type: 'rooms',
              id: roomId,
            },
          },
        },
        attributes: {
          'to-date': toDate,
          'from-date': fromDate,
        },
      },
    })
    return co2ReportEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

export async function fetchUsageReport({
  householdId,
  fromDate,
  toDate,
  dataType,
  dataAttributes,
}) {
  try {
    // dataType 'heat-cost-reports' => endpoint '/reports/heat-cost' etc.
    const endpoint = `/v6/reports/${dataType.replace('-reports', '')}`
    const { data } = await apiProxy().post(endpoint, {
      data: {
        type: dataType,
        relationships: {
          household: {
            data: {
              type: 'households',
              id: householdId,
            },
          },
        },
        attributes: {
          'from-date': dayjs(fromDate).format('YYYY-MM-DD'),
          'to-date': dayjs(toDate).format('YYYY-MM-DD'),
          'days-per-point': 1,
          ...dataAttributes,
        },
      },
    })
    return usageReportEndpointConverter(data)
  } catch (error) {
    handleError(error)
  }
}

function suppressError(message = '') {
  // The _avast_submit error is from a browser plugin
  if (message.indexOf('_avast_submit') >= 0) {
    return true
  } else {
    return false
  }
}

export function logEvent({ level = 'info', message }) {
  // Don't log events in development or test scenarios or for suppressed errors
  if (!/wisehome.dk/.test(window.location.hostname) || suppressError(message)) {
    return false
  }
  try {
    const { activeApiKey, activeSudoAccount, accountList } = accounts.get()
    // level can be 'info', 'warn' or 'error'
    realApi.post('/v6/logs', {
      data: {
        type: 'logs',
        attributes: {
          level,
          message: [
            message,
            window.location.href,
            activeApiKey
              ? `account ${
                  (accountList.find((x) => x.apiKey === activeApiKey) || {}).id
                }`
              : 'demo mode',
            activeSudoAccount ? `sudo ${activeSudoAccount}` : '',
            `${window.innerWidth}x${window.innerHeight}`,
            window.navigator.userAgent,
          ]
            .filter(Boolean)
            .join(' :: '),
          application: 'elrond',
        },
      },
    })
  } catch (ignore) {
    // If logging an error fails, there's really nowhere to log it
  }
}

// Normalizing, non-converting endpoints ==================================== //

// Normalize a JSON API response and merge with an existing resources store
export const updateResources = (newResources) => (existingResources) => {
  return mergeDeepRight(existingResources, normalize(newResources))
}

// Remove a resource from a normalized store
const removeResource = (type, id) => (existingResources) => {
  return dissocPath([type, id], existingResources)
}

// Accounts ----------------------------------------------------------------- //
export async function searchAdminAccounts({ searchTerm = '' }, updaterFunc) {
  try {
    // Only used by an Angel user, therefore search the real API as angel
    const { data } = await realApiAsAngel.get(
      `/v6/accounts?page[size]=10&filter[role]=admin&filter[name-search]=${searchTerm}`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function getUserActivation({ token }, updaterFunc) {
  try {
    // Account activation should always go through the real API
    const { data } = await realApi.get(`/v6/user-activations/${token}`)
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function activateUser({ name, password, token }, updaterFunc) {
  try {
    // Account activation should always go through the real API
    const { data } = await realApi.post('/v6/user-activations', {
      data: {
        attributes: {
          name,
          password,
          token,
        },
        type: 'user-activations',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function inviteAllNotCreatedUsers({ accountId, propertyId }) {
  try {
    const { data } = await realApi.post(
      `/v6/accounts/${accountId}/invite-all-not-created`,
      {
        data: {
          attributes: {
            'property-id': parseInt(propertyId),
          },
          type: 'batch-invitations',
        },
      }
    )

    return data
  } catch (error) {
    handleError(error)
  }
}

export async function inviteAllNotActivatedUsers({ accountId, propertyId }) {
  try {
    const { data } = await realApi.post(
      `/v6/accounts/${accountId}/invite-all-not-activated`,
      {
        data: {
          attributes: {
            'property-id': parseInt(propertyId),
          },
          type: 'batch-invitations',
        },
      }
    )

    console.log('FROM API data ', data)

    return data
  } catch (error) {
    handleError(error)
  }
}

// Account payments --------------------------------------------------------- //
export async function createAccountPayment(
  { accountingDate, tenancyId, fiscalYearId, totalAmount, vatAmount },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().post(
      '/v6/account-payments?include=fiscal-year',
      {
        data: {
          attributes: {
            'accounting-date': accountingDate
              ? dayjs(accountingDate).format('YYYY-MM-DD')
              : null,
            currency: CURRENCY_CODE,
            'total-amount': Math.round(totalAmount),
            'vat-amount': Math.round(vatAmount),
          },
          relationships: {
            'fiscal-year': {
              data: {
                id: fiscalYearId,
                type: 'fiscal-years',
              },
            },
            tenancy: {
              data: {
                id: tenancyId,
                type: 'tenancies',
              },
            },
          },
          type: 'account-payments',
        },
      }
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function deleteAccountPayment({ type, id }, updaterFunc) {
  try {
    await apiProxy().delete(`/v6/account-payments/${id}`)
    if (updaterFunc) {
      return updaterFunc(removeResource(type, id))
    }
  } catch (error) {
    handleError(error)
  }
}

export async function toggleExcludedAccountPayment(
  { excluded, id },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().patch(`/v6/account-payments/${id}`, {
      data: {
        attributes: {
          excluded,
        },
        id: id,
        type: 'account-payments',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function createVacancyAccountPayment(
  {
    accountingDate,
    totalAmount,
    vatAmount,
    vacancyNumber,
    householdId,
    fiscalYearId,
  },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().post('/v6/vacancy-account-payments', {
      data: {
        attributes: {
          'accounting-date': accountingDate
            ? dayjs(accountingDate).format('YYYY-MM-DD')
            : null,
          'total-amount': totalAmount,
          'vat-amount': vatAmount,
          'vacancy-number': vacancyNumber,
        },
        relationships: {
          'fiscal-year': {
            data: {
              id: fiscalYearId,
              type: 'fiscal-years',
            },
          },
          household: {
            data: {
              id: householdId,
              type: 'households',
            },
          },
        },
        type: 'vacancy-account-payments',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function deleteVacancyAccountPayment({ type, id }, updaterFunc) {
  try {
    await apiProxy().delete(`/v6/vacancy-account-payments/${id}`)
    if (updaterFunc) {
      return updaterFunc(removeResource(type, id))
    }
  } catch (error) {
    handleError(error)
  }
}

export async function toggleExcludedVacancyAccountPayment(
  { excluded, id },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().patch(
      `/v6/vacancy-account-payments/${id}`,
      {
        data: {
          attributes: {
            excluded,
          },
          id: id,
          type: 'vacancy-account-payments',
        },
      }
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Households --------------------------------------------------------------- //
export async function loadHousehold({ householdId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/households/${householdId}?include=external-households,alarm-statuses,address.property,tenant.email-settings,vacancies,tenancies.tenant.email-settings`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Statements --------------------------------------------------------------- //
export async function loadStatements(_, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/statements?page[size]=999&include=property.external-properties,property.addresses.households,fiscal-years.expenses,fiscal-years.account-payments,fiscal-years.tenancies`
    )

    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadStatementsByEndDate(
  { statementEndDateFrom, statementEndDateTo },
  updaterFunc
) {
  try {
    let filterByEndDates = []
    if (statementEndDateFrom) {
      filterByEndDates.push(`filter[end-date-from]=${statementEndDateFrom}`)
    }
    if (statementEndDateTo) {
      filterByEndDates.push(`filter[end-date-to]=${statementEndDateTo}`)
    }
    const filterBy = filterByEndDates.join('&')
    const { data } = await apiProxy().get(
      `/v6/statements?${filterBy}&include=property.external-properties,property.addresses.households,fiscal-years.expenses,fiscal-years.account-payments,fiscal-years.tenancies`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadStatementWithExpenses({ statementId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/statements/${statementId}?include=fiscal-years.expenses,fiscal-years.distributed-expenses,fiscal-years.fuel-check-points,property`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadStatementWithConfig({ statementId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/statements/${statementId}?include=property,statement-config`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadStatementWithExpensesAndAccountPayments(
  { statementId },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().get(
      `/v6/statements/${statementId}?include=fiscal-years.expenses,fiscal-years.account-payments,fiscal-years.tenancies.tenant,fiscal-years.vacancies.household,fiscal-years.vacancies.vacancy-info.vacancy-account-payments,property.addresses.households,property.external-properties,statement-files`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadVacancyInfos({ householdId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/households/${householdId}?include=vacancies`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadStatementWithFiles({ statementId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/statements/${statementId}?include=statement-files.external-resource`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadPriorStatements(
  { propertyId, statementEndDate },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().get(
      `/v6/statements?filter[property-id]=${propertyId}&filter[end-date-from]=${statementEndDate}&filter[end-date-to]=${statementEndDate}&include=fiscal-years.distributed-expenses`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadPriorStatementsWithAccountPayments(
  { propertyId, statementEndDate },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().get(
      `/v6/statements?filter[property-id]=${propertyId}&filter[end-date-from]=${statementEndDate}&filter[end-date-to]=${statementEndDate}&include=fiscal-years.account-payments,fiscal-years.tenancies`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function lockStatement({ statementId }, updaterFunc) {
  try {
    const { data } = await apiProxy().patch(`/v6/statements/${statementId}`, {
      data: {
        attributes: {
          locked: true,
        },
        id: statementId,
        type: 'statements',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function updateStatementConfig({ id, config = {} }, updaterFunc) {
  try {
    const { data } = await apiProxy().patch(`/v6/statement-configs/${id}`, {
      data: {
        attributes: {
          ...config,
        },
        id: id,
        type: 'statement-configs',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Expenses ----------------------------------------------------------------- //
export async function createExpense(
  {
    accountingDate,
    companyName,
    fiscalYearId,
    fuelType,
    totalAmount,
    vatAmount,
    usage,
  },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().post('/v6/expenses', {
      data: {
        attributes: {
          'accounting-date': accountingDate
            ? dayjs(accountingDate).format('YYYY-MM-DD')
            : null,
          'company-name': companyName || null,
          'total-amount': totalAmount,
          'vat-amount': vatAmount,
          'fuel-type': usage && fuelType ? fuelType : null,
          usage: usage ?? null,
        },
        relationships: {
          'fiscal-year': {
            data: {
              id: fiscalYearId,
              type: 'fiscal-years',
            },
          },
        },
        type: 'expenses',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function updateExpense(
  { id, accountingDate, companyName, fuelType, totalAmount, vatAmount, usage },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().patch(`/v6/expenses/${id}`, {
      data: {
        attributes: {
          'accounting-date': accountingDate
            ? dayjs(accountingDate).format('YYYY-MM-DD')
            : null,
          'company-name': companyName || null,
          'total-amount': totalAmount,
          'vat-amount': vatAmount,
          'fuel-type': usage && fuelType ? fuelType : null,
          usage: usage ?? null,
        },
        id,
        type: 'expenses',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function deleteExpense({ expenseId }, updaterFunc) {
  try {
    await apiProxy().delete(`/v6/expenses/${expenseId}`)
    if (updaterFunc) {
      return updaterFunc(removeResource('expenses', expenseId))
    }
  } catch (error) {
    handleError(error)
  }
}

// Distributed expenses ----------------------------------------------------- //
export async function createDistributedExpense(
  {
    fiscalYearId,
    settlementKeyId,
    businessOnly = false,
    expenseType,
    title,
    amount,
  },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().post('/v6/distributed-expenses', {
      data: {
        attributes: {
          amount,
          title: title || null,
          'business-only': businessOnly,
          'expense-type': expenseType,
        },
        relationships: {
          'fiscal-year': {
            data: {
              id: fiscalYearId,
              type: 'fiscal-years',
            },
          },
          'settlement-key': {
            data: {
              id: settlementKeyId,
              type: 'settlement-keys',
            },
          },
        },
        type: 'distributed-expenses',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function updateDistributedExpense(
  { id, settlementKeyId, businessOnly = false, expenseType, title, amount },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().patch(`/v6/distributed-expenses/${id}`, {
      data: {
        attributes: {
          amount,
          title: title || null,
          'business-only': businessOnly,
          'expense-type': expenseType,
        },
        relationships: {
          'settlement-key': {
            data: {
              id: settlementKeyId,
              type: 'settlement-keys',
            },
          },
        },
        id,
        type: 'distributed-expenses',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function deleteDistributedExpense(
  { distributedExpenseId },
  updaterFunc
) {
  try {
    await apiProxy().delete(`/v6/distributed-expenses/${distributedExpenseId}`)
    if (updaterFunc) {
      return updaterFunc(
        removeResource('distributedExpenses', distributedExpenseId)
      )
    }
  } catch (error) {
    handleError(error)
  }
}

// Fuel Checkpoints ----------------------------------------------------------------- //
export async function createFuelCheckpoint(
  { fiscalYearId, fuelType, remaining },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().post('/v6/fuel-check-points', {
      data: {
        attributes: {
          'fuel-type': remaining && fuelType ? fuelType : null,
          remaining: remaining ?? null,
        },
        relationships: {
          'fiscal-year': {
            data: {
              id: fiscalYearId,
              type: 'fiscal-years',
            },
          },
        },
        type: 'fuel-check-points',
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function updateFuelCheckpoint(
  { fuelCheckpointId, remaining },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().patch(
      `/v6/fuel-check-points/${fuelCheckpointId}`,
      {
        data: {
          id: fuelCheckpointId,
          attributes: {
            remaining: remaining,
          },
          type: 'fuel-check-points',
        },
      }
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function deleteFuelCheckpoint({ fuelCheckpointId }, updaterFunc) {
  try {
    await apiProxy().delete(`/v6/fuel-check-points/${fuelCheckpointId}`)
    if (updaterFunc) {
      return updaterFunc(removeResource('fuelCheckPoints', fuelCheckpointId))
    }
  } catch (error) {
    handleError(error)
  }
}

// Password reset ----------------------------------------------------------- //
export async function requestPasswordReset({ email }) {
  try {
    // The reset password request should always go through the real API
    await realApi.post('/v6/password-resets', {
      data: {
        attributes: {
          email,
        },
        type: 'password-resets',
      },
    })
  } catch (error) {
    handleError(error)
  }
}

export async function performPasswordReset({ token, password }) {
  try {
    // Resetting a password should always go through the real API
    await realApi.patch(`/v6/password-resets/${token}`, {
      data: {
        attributes: {
          password,
        },
        id: token,
        type: 'password-resets',
      },
    })
  } catch (error) {
    handleError(error)
  }
}

// Properties --------------------------------------------------------------- //
export async function loadProperty({ propertyId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/properties/${propertyId}?include=external-properties,addresses.households.tenant,addresses.households.tenancies,addresses.households.settlement-values,addresses.households.vacancies,addresses.households.external-households,addresses.households.alarm-statuses,statements.fiscal-years.water-usage-meter-comparison`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadProperties(_, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      '/v6/properties?page[size]=999&include=addresses.households.tenant,addresses.households.tenancies,addresses.households.vacancies,addresses.households.alarm-statuses,addresses.households.external-households,external-properties'
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function loadPropertiesAndHouseholds(_, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      '/v6/properties?page[size]=999&include=addresses.households.tenant,addresses.households.alarm-statuses'
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Email settings ----------------------------------------------------------- //
export async function patchEmailSettings(
  { accountId, emailSettingsId, receivesVentilationAlarm },
  updaterFunc
) {
  try {
    if (!accountId) {
      throw new Error('Mangler id på beboer')
    }
    if (!emailSettingsId) {
      throw new Error('Mangler id på email indstillinger')
    }
    const { data } = await apiProxy().patch(
      `/v6/accounts/${accountId}/email-settings`,
      {
        data: {
          type: 'account-email-settings',
          id: emailSettingsId,
          attributes: {
            'ventilation-alarm':
              receivesVentilationAlarm === true ? true : false,
          },
        },
      }
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Tenancies & tenant accounts ---------------------------------------------- //
export async function createTenancy(
  { householdId, tenantId, moveInDate, moveOutDate },
  updaterFunc
) {
  try {
    if (!householdId) {
      throw new Error('Mangler id på husstand for at oprette periode')
    }
    if (!tenantId) {
      throw new Error('Mangler id på beboer for at oprette periode')
    }
    const { data } = await apiProxy().post('/v6/tenancies', {
      data: {
        type: 'tenancies',
        relationships: {
          tenant: {
            data: {
              type: 'accounts',
              id: tenantId,
            },
          },
          household: {
            data: {
              type: 'households',
              id: householdId,
            },
          },
        },
        attributes: {
          ...(!isUndefined(moveInDate) && { 'move-in-date': moveInDate }),
          ...(!isUndefined(moveOutDate) && { 'move-out-date': moveOutDate }),
        },
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function updateTenancy(
  { id, moveInDate, moveOutDate },
  updaterFunc
) {
  try {
    if (!id) {
      throw new Error('Mangler id på periode for at kunne redigere den')
    }
    const { data } = await apiProxy().patch(`/v6/tenancies/${id}`, {
      data: {
        type: 'tenancies',
        id: id,
        attributes: {
          ...(!isUndefined(moveInDate) && { 'move-in-date': moveInDate }),
          ...(!isUndefined(moveOutDate) && { 'move-out-date': moveOutDate }),
        },
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function deleteTenancy({ id }, updaterFunc) {
  try {
    await apiProxy().delete(`/v6/tenancies/${id}`)
    if (updaterFunc) {
      return updaterFunc(removeResource('tenancies', id))
    }
  } catch (error) {
    handleError(error)
  }
}

export async function createTenant({ name, phone, email }, updaterFunc) {
  try {
    const { data } = await apiProxy().post(
      '/v6/accounts?include=email-settings',
      {
        data: {
          type: 'accounts',
          attributes: {
            role: 'tenant',
            name,
            phone,
            email,
          },
        },
      }
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

export async function updateTenant(
  { accountId, name, phone, email },
  updaterFunc
) {
  try {
    if (!accountId) {
      throw new Error('Mangler id på konto for at kunne redigere beboer')
    }
    const { data } = await apiProxy().patch(`/v6/accounts/${accountId}`, {
      data: {
        type: 'accounts',
        id: accountId,
        attributes: {
          name: name || null,
          phone: phone || null,
          email: email || null,
        },
      },
    })
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Devices ------------------------------------------------------------------ //
export async function loadDevicesByHousehold({ householdId }, updaterFunc) {
  try {
    const { data } = await apiProxy().get(
      `/v6/devices?filter[household-id]=${householdId}&include=room,device-type,last-measurements`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}

// Settlement keys ---------------------------------------------------------- //
export async function loadSettlementKeysForProperty(
  { propertyId },
  updaterFunc
) {
  try {
    const { data } = await apiProxy().get(
      `/v6/settlement-keys?filter[for-property-id]=${propertyId}`
    )
    if (updaterFunc) {
      return updaterFunc(updateResources(data))
    } else {
      return data
    }
  } catch (error) {
    handleError(error)
  }
}
